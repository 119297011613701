<template>
  <div id="app" class="m-8 md:m-24 space-y-12 font-sans">
    <h1 class="text-5xl md:text-9xl max-w-5xl font-bold text-black">A modern CRM for pioneering mortgage &amp; insurance brokers</h1>

    <p class="text-2xl md:text-4xl max-w-4xl leading-normal text-gray-400">Manage your cases, contacts, tasks, customisable workflows, our unique automation engine, leading sourcing tools, a beautiful client portal, a unique form-builder and a LOT more.</p>

    <p class="text-2xl md:text-4xl max-w-4xl leading-normal "><a class="text-white transform hover:-translate-y-1 ease-in-out duration-300 bg-gradient-to-br from-pink-500 to-indigo-500 p-4 px-8 inline-block" href="https://www.spudmail.co.uk/h/i/90CFBE48BD5E9425" target="_blank">Subscribe to our launch mailing list</a></p>

    <p class="text-xl text-gray-400">Brought to you by your friends at <a href="https://plannrcrm.com">Plannr Technologies Limited</a>. Tweet us
      <a href="https://twitter.com/plannrcrm" target="_blank">@plannrcrm</a></p>

  </div>
</template>

<script>

export default {
  name: 'App',
}
</script>